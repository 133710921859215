<template>
  <div class="contacts">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-5 mb-md-0">
          <h3 class="h3 mb-4">Наши контакты</h3>

          <template v-for="(item, index) in contacts">
            <IconText
              :img="item.img"
              :text="item.text"
              :key="`contacts-${index}`"
              class="mb-3"
            ></IconText>
          </template>
        </div>

        <div class="col-md-6">
          <h3 class="h3 mb-4">График работы</h3>

          <template v-for="(item, index) in workTime">
            <IconText
              :img="item.img"
              :text="item.text"
              :key="`workTime-${index}`"
            ></IconText>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';

export default {
  name: 'Contacts',
  components: {
    IconText,
  },
  data: () => ({
    contacts: [
      {
        img: require('@/assets/images/icon-1.svg'),
        text: '443083, г. Самара, ул. Победы, д. 6',
      },
      {
        img: require('@/assets/images/icon-2.svg'),
        text: '<a href="tel:+79277465656">+7 (927) 746-56-56</a>',
      },
      {
        img: require('@/assets/images/icon-2.svg'),
        text: '<a href="tel:+79608124124">+7 (960) 8-124-124</a>',
      },
      {
        img: require('@/assets/images/icon-3.svg'),
        text: '<a href="mailto:avtoskolapobeda@gmail.com">avtoskolapobeda@gmail.com</a>',
      },
    ],
    workTime: [
      {
        img: require('@/assets/images/icon-4.svg'),
        text: `
          <p>Понедельник - Пятница — с 9:00 до 18:00</p>
          <p>Суббота — с 10:00 до 14:00</p>
          <p>Воскресенье — Выходной</p>
        `,
      },
    ],
  }),
};
</script>

<style lang="scss">
  .contacts {
    padding: 80px 0;
    background: #F7F7F7;

    @media (max-width: 575px) {
      padding: 48px 0;
    }
  }
</style>
