<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-lg-6">
          <Logo></Logo>
        </div>

        <div class="col-md-3">
          <!-- <p><a href="#">Пройти опрос</a></p>
          <p><a href="#">Консультация по телефону</a></p>
          <p><a href="#">Контакты</a></p> -->
        </div>

        <div class="col-md-auto col-lg-3">
          <p>Обратная Связь:</p>
          <p><a href="tel:89277465656">8 (927) 746 56 56</a></p>
          <p class="mb-4">
            <a href="mailto:avtoskolapobeda@gmail.com">avtoskolapobeda@gmail.com</a>
          </p>

          <SocIconSmall></SocIconSmall>
        </div>
      </div>

      <div class="footer__copyright">2022 &copy;</div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/Logo.vue';
import SocIconSmall from '@/components/SocIconSmall.vue';

export default {
  name: 'Footer',
  components: {
    Logo,
    SocIconSmall,
  },
};
</script>

<style lang="scss">
  .footer {
    font-weight: 700;
    background-color: #FFB51B;
    background-image: url('~@/assets/images/footer-bg.png');
    background-position: calc(50% - 300px) 100%;
    background-repeat: no-repeat;
    padding: 60px 0 15px;

    &__copyright {
      text-align: center;
      font-weight: 400;
      margin-top: 80px;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        color: #000;
      }
    }
    .logo {
      margin-bottom: 46px;

      @media (max-width: 767px) {
        margin-bottom: 1rem;
      }
    }

    @media (max-width: 575px) {
      padding-top: 30px;
    }
  }
</style>
