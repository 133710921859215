<template>
  <div class="video">
    <video
      :src="video"
      controls
    ></video>
  </div>
</template>

<script>
export default {
  name: 'Video',
  data: () => ({
    video: require('@/assets/video/video.mp4'),
  }),
};
</script>

<style lang="scss">
  .video {
    position: relative;
    height: 100%;
    min-height: 300px;
    background-color: #000;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 991px) {
      min-height: 370px;
    }

    @media (max-width: 767px) {
      min-height: 300px;
    }

    @media (max-width: 575px) {
      min-height: 250px;
    }
  }
</style>
