<template>
  <div class="soc-icon-menu">
    <a
      href="#"
      class="soc-icon-menu__btn"
      :class="(active) ? 'soc-icon-menu__btn--active' : ''"
      @click.prevent="active = !active"
    ></a>

    <div
      class="soc-icon-menu__block"
      :class="(active) ? 'soc-icon-menu__block--active' : ''"
    >
      <template v-for="(item, index) in items">
        <a
          :key="`soc-item-${index}`"
          :href="item.href"
          class="soc-icon-menu__link"
          target="_blank"
        >
          <img
            :src="item.img"
            alt="icon"
          />
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocIconMenu',
  data: () => ({
    active: false,
    items: [
      {
        img: require('@/assets/images/phone-2.svg'),
        href: 'tel:89277465656',
      },
      {
        img: require('@/assets/images/soc-icon-1.svg'),
        href: 'https://tlgg.ru/@zarulem163',
      },
      {
        img: require('@/assets/images/soc-icon-2.svg'),
        href: 'https://vk.com/avtoshkolasamara163',
      },
      {
        img: require('@/assets/images/soc-icon-3.svg'),
        href: 'https://viber.click/79277465656',
      },
      {
        img: require('@/assets/images/soc-icon-4.svg'),
        href: 'https://wapp.click/79277465656',
      },
    ],
  }),
};
</script>

<style lang="scss">
  .soc-icon-menu {
    position: relative;

    &__btn {
      display: block;
      width: 34px;
      height: 34px;
      background-image: url('~@/assets/images/phone.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &--active {
        background-image: url('~@/assets/images/minus.svg');
      }
    }

    &__block {
      position: absolute;
      top: 70px;
      left: calc(50% - 26px);
      width: 52px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 9px 7px 23px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      padding: 12px 0;
      opacity: 0;
      transition: opacity .25s ease-in-out,
                  left 0s linear .25s;

      &--active {
        opacity: 1;
        transition: opacity .25s ease-in-out;

      }
    }

    &__link {
      width: 30px;
      height: 30px;
      margin-bottom: 12px;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: scale-down;
        object-position: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
