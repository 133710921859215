<template>
  <div class="logo">
    <div class="logo__img">
      <img
        :src="img"
        alt="logo"
      />
    </div>

    <div
      class="logo__text"
      v-html="text"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data: () => ({
    img: require('@/assets/images/logo.png'),
    text: 'ЧОУ ДПО<br>"АВТОШКОЛА-САМАРА"',
  }),
};
</script>

<style lang="scss">
  .logo {
    display: flex;
    align-items: center;

    &__img {
      width: 168px;
      height: 106px;
      flex-shrink: 0;
      margin-right: 25px;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: contain;
        object-position: center;
      }

      @media (max-width: 1199px) {
        width: 120px;
        margin-right: 15px;
      }

      @media (max-width: 991px) {
        width: 62px;
        height: 62px;
      }
    }

    &__text {
      font-size: 1.5rem;
      line-height: 1.2;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
      }

      @media (max-width: 575px) {
        font-size: 1rem;
      }

      @media (max-width: 359px) {
        font-size: .75rem;
      }
    }
  }
</style>
