<template>
  <div class="container quiz" id="quiz">
    <div class="quiz__title mb-3">{{ title }}</div>

    <div data-marquiz-id="6257ce923add3f003f8c5403"></div>
  </div>
</template>

<script>
export default {
  name: 'Quiz',
  data: () => ({
    title: 'Пройди тест и выиграй скидку в 1000 рублей на обучение!',
  }),
};
</script>

<style lang="scss">
  .quiz {
    margin-bottom: 80px;

    &__title {
      text-align: center;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 1.2;

      @media (max-width: 575px) {
        font-size: 1.15rem;
      }
    }

    @media (max-width: 575px) {
      margin-bottom: 48px;
    }
  }
</style>
