<template>
  <header class="header">
    <div class="container header__container">
      <div class="header__bg"></div>
      <div class="header__shadow"></div>
      <div class="header__logo-bg"></div>

      <div class="header__content">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <Logo></Logo>
          </div>

          <div class="col-lg-auto ms-xxl-auto d-none d-lg-block">
            <SocIcon></SocIcon>
          </div>

          <div class="col-lg-auto d-none d-lg-block">
            <Phone></Phone>
          </div>

          <!-- <div class="col-auto d-lg-none ms-auto px-0">
            <a href="https://wapp.click/79277465656" target="_blank">
              <img :src="require('@/assets/images/whats-app-small.svg')" alt="whats-app">
            </a>
          </div> -->

          <!-- <div class="col-auto d-lg-none ps-1">
            <a href="tel:89277465656">
              <img :src="require('@/assets/images/phone.svg')" alt="phone">
            </a>
          </div> -->

          <div class="col-auto d-lg-none ps-1">
            <SocIconMenu></SocIconMenu>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/Logo.vue';
import SocIcon from '@/components/SocIcon.vue';
import Phone from '@/components/Phone.vue';
import SocIconMenu from '@/components/SocIconMenu.vue';

export default {
  name: 'Header',
  components: {
    Logo,
    SocIcon,
    Phone,
    SocIconMenu,
  },
};
</script>

<style lang="scss">
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &__container {
      position: relative;

      &:before,
      &:after {
        content: '';
        background-color: #fff;
        position: absolute;
        bottom: 3px;
        width: 80px;
        height: 130%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        z-index: 2;

        @media (max-width: 991px) {
          display: none;
        }
      }

      &:before {
        left: -99px;
        transform: rotate(-35deg);
        border-radius: 0 0 0 15px;
      }

      &:after {
        right: -99px;
        transform: rotate(35deg);
        border-radius: 0 0 15px 0;
      }
    }

    &__bg {
      position: absolute;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: -50px;
      width: calc(100% + 100px);
      height: 100%;
      border-radius: 0 0 15px 15px;
      z-index: 3;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &__shadow {
      position: absolute;
      top: 0;
      left: -50px;
      width: calc(100% + 100px);
      height: 100%;
      box-shadow: 0 25px 20px -25px rgba(0, 0, 0, 0.3);

      @media (max-width: 991px) {
        display: none;
      }
    }

    &__logo-bg {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -17px;
        width: 180px;
        height: 180px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
      }

      @media (max-width: 1199px) {
        display: none;
      }
    }

    &__content {
      position: relative;
      padding: 16px 0;
      z-index: 5;

      @media (max-width: 991px) {
        padding: 10px 0;
      }
    }

    @media (max-width: 991px) {
      background-color: #fff;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    }
  }
</style>
