<template>
  <div class="container map">
    <h2 class="h2 mb-4">{{ title }}</h2>

    <div id="map"></div>
  </div>
</template>

<script>
export default {
  name: 'Map',
  data: () => ({
    title: 'Наши филиалы',
  }),
};
</script>

<style lang="scss">
  .map {
    margin-bottom: 80px;

    @media (max-width: 575px) {
      margin-bottom: 48px;
    }
  }

  #map {

    ymaps[class$="-ground-pane"] {
      filter: grayScale(1);
    }
  }
</style>
