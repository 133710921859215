<template>
  <div class="soc-icon-small">
    <template v-for="(item, index) in items">
      <a
        :key="`soc-item-small-${index}`"
        :href="item.href"
        class="soc-icon-small__link"
        target="_blank"
      >
        <img
          :src="item.img"
          alt="icon"
        />
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SocIconSmall',
  data: () => ({
    items: [
      {
        img: require('@/assets/images/soc-icon-1-orange.svg'),
        href: 'https://tlgg.ru/@zarulem163',
      },
      {
        img: require('@/assets/images/soc-icon-2-orange.svg'),
        href: 'https://vk.com/avtoshkolasamara163',
      },
      {
        img: require('@/assets/images/soc-icon-3-orange.svg'),
        href: 'https://viber.click/79277465656',
      },
      {
        img: require('@/assets/images/soc-icon-4-orange.svg'),
        href: 'https://wapp.click/79277465656',
      },
    ],
  }),
};
</script>

<style lang="scss">
  .soc-icon-small {
    display: flex;
    align-items: center;

    &__link {
      width: 32px;
      height: 32px;
      margin: 0 5px;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: scale-down;
        object-position: center;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
