<template>
  <div id="app">
    <Header></Header>
    <Banner></Banner>
    <Info></Info>
    <Quiz></Quiz>
    <Gallery></Gallery>
    <Map></Map>
    <Contacts></Contacts>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Banner from '@/components/Banner.vue';
import Info from '@/components/Info.vue';
import Quiz from '@/components/Quiz.vue';
import Gallery from '@/components/Gallery.vue';
import Map from '@/components/Map.vue';
import Contacts from '@/components/Contacts.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Banner,
    Info,
    Quiz,
    Gallery,
    Map,
    Contacts,
    Footer,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/font/stylesheet.css';

  #app {
    font-family: 'Proxima Nova Rg', sans-serif;
    color: #000;
    overflow: hidden;
    position: relative;
  }

  .h2 {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.2;

    @media (max-width: 575px) {
      font-size: 1.15rem;
    }
  }

  .h3 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.2;

    @media (max-width: 575px) {
      font-size: 1.15rem;
    }
  }
</style>
