<template>
  <div class="banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div
            class="banner__title"
            v-html="title"
          ></div>

          <div v-html="text"></div>

          <!-- <div
            class="banner__caption"
            v-html="caption"
          ></div> -->

          <div class="banner__block-text" v-html="blockText"></div>

          <a
            :href="btn.href"
            class="banner__btn d-none d-lg-flex"
          >{{ btn.text }}</a>
        </div>

        <div class="col-lg-4 order-lg-first">
          <div class="banner__img">
            <div>
              <img
                :src="img"
                alt="banner"
              />
            </div>

            <a
              :href="btn.href"
              class="banner__btn d-lg-none"
            >{{ btn.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data: () => ({
    title: 'Самое современное обучение ПДД с тестами после урока',
    text: 'Пройди тест и стань участником розыгрыша*',
    caption: '<sup>*</sup>лотерея бесплатного обучения',
    blockText: 'Курсы от <span>10 000 ₽</span>',
    btn: {
      text: 'Пройти опрос',
      href: '#quiz',
    },
    img: require('@/assets/images/banner-img.webp'),
  }),
};
</script>

<style lang="scss">
  .banner {
    padding-top: 180px;
    background-image: url('~@/assets/images/banner-bg-4.jpg'),
                      linear-gradient(to right, #fff 60%, #ffb61b 40%);
    background-position: calc(50% + 400px) 0;
    background-repeat: no-repeat;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 80px;
    overflow: hidden;

    &__title {
      font-size: 4.31rem;
      line-height: 1;
      margin: 40px 0 25px;

      @media (max-width: 1199px) {
        font-size: 3.5rem;
        margin: 0 0 25px;
      }

      @media (max-width: 767px) {
        font-size: 3rem;
      }

      @media (max-width: 575px) {
        font-size: 1.75rem;
        margin-bottom: 15px;
      }
    }

    &__caption {
      font-size: 1.5rem;
      font-weight: 400;
      margin-top: 10px;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
      }

      @media (max-width: 767px) {
        font-size: 1rem;
      }

      @media (max-width: 575px) {
        font-size: .75rem;
      }
    }

    &__block-text {
      background: #FFD278;
      border-radius: 10px;
      font-size: 1.5rem;
      line-height: 1.2;
      display: inline-block;
      padding: 10px 30px;
      margin-top: 24px;

      span {
        font-weight: 700;
        font-size: 1.75rem;
        color: #FF6E30;
        vertical-align: baseline;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 532px;
      height: 118px;
      font-size: 1.85rem;
      font-weight: 400;
      line-height: 1.2;
      background-color: #FF6E30;
      border-radius: 32px;
      color: #fff;
      text-decoration: none;
      margin-top: 68px;

      &:hover {
        color: #fff;
      }

      &:before,
      &:after {
        content: '';
        width: 52px;
        height: 52px;
        background-image: url('~@/assets/images/arrow-right.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 30px;

        @media (max-width: 575px) {
          width: 30px;
          height: 30px;
        }
      }

      &:after {
        margin-left: 30px;
        margin-right: 0;
        transform: scale(-1, 1);
      }

      @media (max-width: 1199px) {
        max-width: 460px;
        height: 90px;
        margin-top: 50px;
      }

      @media (max-width: 991px) {
        position: absolute;
        bottom: 50px;
        height: 80px;
        width: 100%;
      }

      @media (max-width: 575px) {
        font-size: 1.25rem;
      }
    }

    &__img {
      position: relative;
      height: 722px;

      div {
        width: 605px;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-60%);

        img {
          object-fit: contain;
          object-position: bottom;
          width: 100%;
          min-height: 100%;

          @media (max-width: 767px) {
            max-height: 400px;
          }
        }

        @media (max-width: 1199px) {
          width: 400px;
        }

        @media (max-width: 991px) {
          position: static;
          transform: translateX(0);
          height: 100%;
        }

        @media (max-width: 575px) {
          width: 100%;
        }
      }

      @media (max-width: 1199px) {
        height: calc(100% + 50px);
        margin-bottom: -50px;
      }

      @media (max-width: 991px) {
        display: inline-block;
        height: auto;
      }
    }

    @media (max-width: 1199px) {
      font-size: 1.75rem;
      padding-bottom: 50px;
    }

    @media (max-width: 991px) {
      padding-top: 120px;
    }

    @media (max-width: 767px) {
      font-size: 1.5rem;
    }

    @media (max-width: 575px) {
      padding-top: 100px;
      font-size: 1.125rem;
      margin-bottom: 48px;
    }
  }
</style>
