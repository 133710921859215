<template>
  <div class="container info">
    <div class="row">
      <div class="col-lg-6 mb-5 mb-lg-0">
        <BlockInfo></BlockInfo>
      </div>

      <div class="col-lg-6">
        <Video></Video>
      </div>
    </div>
  </div>
</template>

<script>
import BlockInfo from '@/components/BlockInfo.vue';
import Video from '@/components/Video.vue';

export default {
  name: 'Info',
  components: {
    BlockInfo,
    Video,
  },
};
</script>

<style lang="scss">
  .info {
    margin-bottom: 80px;

    @media (max-width: 575px) {
      margin-bottom: 48px;
    }
  }
</style>
