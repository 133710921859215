<template>
  <div class="block-info">
    <div class="block-info__title">{{ title }}</div>

    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'BlockInfo',
  data: () => ({
    title: 'Бесплатные',
    text: 'пробные занятия по теории и вождению',
  }),
};
</script>

<style lang="scss">
  .block-info {
    background-color: #FFB51B;
    background-image: url('~@/assets/images/info-block-bg.webp');
    background-position: left bottom;
    background-repeat: no-repeat;
    padding: 30px 45px 214px 220px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2;
    border-radius: 24px 0 24px 0;

    &__title {
      font-size: 3rem;

      @media (max-width: 575px) {
        font-size: 1.75rem;
      }
    }

    @media (max-width: 1199px) {
      background-size: 100%;
      padding: 30px 30px 150px 150px;
    }

    @media (max-width: 991px) {
      padding: 30px 30px 220px 250px;
    }

    @media (max-width: 767px) {
      padding: 30px 30px 150px 180px;
    }

    @media (max-width: 575px) {
      font-size: 1rem;
      padding: 30px 30px 35% 30%;
    }
  }
</style>
