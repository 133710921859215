<template>
  <div class="phone">
    <div class="phone__title">{{ title }}</div>

    <a
      :href="`tel:${number}`"
      class="phone__link"
    >{{ phone }}</a>

    <br>{{ text }}
  </div>
</template>

<script>
export default {
  name: 'Phone',
  data: () => ({
    phone: '8 (927) 746 56 56',
    title: 'Быстрая консультация',
    text: 'Звоните, мы сейчас работаем',
  }),
  computed: {
    number() {
      return this.phone.replace(/[^+\d]/g, '');
    },
  },
};
</script>

<style lang="scss">
  .phone {
    line-height: 1.2;

    &__title {
      color: #FE4C0D;
      font-weight: 700;
      font-size: 1.125rem;
    }

    &__link {
      color: #000;
      font-weight: 700;
      font-size: 2.375rem;
      text-decoration: none;

      &:hover {
        color: #000;
      }

      @media (max-width: 1199px) {
        font-size: 2rem;
      }
    }
  }
</style>
