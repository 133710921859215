<template>
  <div class="container gallery">
    <h2 class="h2 mb-4">{{ title }}</h2>

    <div class="gallery__grid">
      <template v-for="(item, index) in items">
        <a
          :href="item.imgBig"
          :key="`gallery-${index}`"
          data-fancybox="gallery"
          class="gallery__link"
        >
          <img
            :src="item.img"
            alt="image"
          >
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { Fancybox } from '@fancyapps/ui';

export default {
  name: 'Gallery',
  data: () => ({
    title: 'Сведения об автошколе',
    items: [
      {
        img: require('@/assets/images/gallery-1.jpg'),
        imgBig: require('@/assets/images/gallery-1.jpg'),
      },
      {
        img: require('@/assets/images/gallery-2.jpg'),
        imgBig: require('@/assets/images/gallery-2.jpg'),
      },
      {
        img: require('@/assets/images/gallery-3.jpg'),
        imgBig: require('@/assets/images/gallery-3.jpg'),
      },
      {
        img: require('@/assets/images/gallery-4.jpg'),
        imgBig: require('@/assets/images/gallery-4.jpg'),
      },
    ],
  }),
  mounted() {
    Fancybox.bind('.gallery__link');
  },
};
</script>

<style lang="scss">
  @import '~@fancyapps/ui/dist/fancybox.css';

  .gallery {
    margin-bottom: 80px;

    &__grid {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-flow: column;
        grid-auto-columns: minmax(250px, 1fr);
        overflow-x: auto;
      }

      @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-auto-columns: minmax(220px, 1fr);
      }
    }

    &__link {
      text-align: center;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 575px) {
      margin-bottom: 48px;
    }
  }
</style>
