<template>
  <div class="icon-text">
    <div class="icon-text__img">
      <img
        :src="img"
        alt="icon"
      >
    </div>

    <div
      class="icon-text__text"
      v-html="text"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'IconText',
  props: {
    img: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .icon-text {
    display: flex;
    align-items: center;

    &__img {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 30px;
      align-self: flex-start;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: scale-down;
        object-position: center;
      }

      @media (max-width: 991px) {
        margin-right: 10px;
      }
    }

     &__text {
      align-self: center;

      a {
        color: #FF6E30;
        text-decoration: none;

        &:hover {
          color: #FF6E30;
        }
      }
     }
  }
</style>
